import {plugin_registry, PluginBase} from "nk-plugin-registry";
import gsap from "gsap";

@plugin_registry.register('PageHeader')
class PageHeader extends PluginBase {
  constructor($node) {
    super($node);
    this._menu_visible = false;
  }

  loaded($node) {
    super.loaded($node);
    this._$navigation_trigger = $node.querySelector('[data-js-select="main-nav-toggle"]');
    this._$navigation_close_trigger = $node.querySelector('[data-js-select="close-overlay"]');
    this._$navigation_overlay = $node.querySelector('[data-js-select="navigation-overlay"]');
    this._$navigation_trigger.addEventListener('click', this._handle_navigation_toggle);
    this._$navigation_close_trigger.addEventListener('click', this._handle_navigation_toggle);
  }

  _handle_navigation_toggle = (event) => {
    event.preventDefault();
    this.menu_visible = !this.menu_visible;
  }

  get menu_visible() {
    return this._menu_visible;
  }

  set menu_visible(new_state) {
    if (new_state) {
      this._show_menu();
    } else {
      this._hide_menu();
    }
    this._menu_visible = new_state;
  }

  _show_menu = () => {
    gsap.to(this._$navigation_overlay,
      {
        duration: 0.25,
        display: "block",
        opacity: 1,
      },
    );
  }

  _hide_menu = () => {
    gsap.to(this._$navigation_overlay,
      {
        duration: 0.25,
        display: "none",
        opacity: 0,
      },
    );
  }
}
